<template>
  <div class="planStatExamination-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchModel.tenderName"
            placeholder="标段名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="planStatExaminationList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
    >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="tender.tenderName" header-align="center" align="center" label="标段"></el-table-column>
      <el-table-column prop="tender.constructionUnit" width="250" header-align="center" align="center" label="承建单位"></el-table-column>
      <el-table-column prop="examinationType" header-align="center" width="80" align="center" label="类型"></el-table-column>
      <el-table-column prop="year" header-align="center" width="80" align="center" label="年份"></el-table-column>
      <el-table-column prop="month" header-align="center" width="80" align="center" label="月份"></el-table-column>
      <el-table-column prop="date" header-align="center" width="80" align="center" label="日期"></el-table-column>
      <el-table-column prop="examinationCode" header-align="center" align="center" label="申报编号"></el-table-column>
      <el-table-column prop="createUser.staffName" header-align="center" align="center" label="申报人"></el-table-column>
      <el-table-column prop="approvalUser.staffName" header-align="center" align="center" label="审核人"></el-table-column>
      <el-table-column prop="approvalStatus" header-align="center" align="center" label="审核状态"></el-table-column>
      <el-table-column width="220" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a @click="handleDetailClick(scope.row)" v-if="!shouldImport(scope.row)" class="mr-2" href="#">明细</a>
          <a @click="handleImportClick(scope.row)" v-else class="mr-2" href="#">导入</a>
          <a @click="handleSubmitClick(scope.row)" v-if="couldTransfer(scope.row)" class="mr-2" href="#">传送</a>
          <span v-else class="mr-2 text-muted" href="#">传送</span>
          <a v-if="finalSubmitShow" @click="handleFinalSubmitClick(scope.row)" class="mr-2" href="#">最终批复</a>
          <a @click="handleDeleteClick(scope.row)" v-if="userGuid === scope.row.approvalUserGuid && userGuid === scope.row.createUserGuid" class="" href="#">删除</a>
          <span v-else class="text-muted" href="#">删除</span>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <plan-stat-examination-form
        ref="planStatExaminationForm"
        :planStatExaminationModel="planStatExaminationModel">
      </plan-stat-examination-form>
    </my-dialog>

    <my-dialog
      ref="submitFormDialog"
      title="传送"
      size="md"
      ok-text="确定"
      cancel-text="取消"
      @ok-click="doSubmit"
      >
      <el-form class="text-center">
        <el-form-item>
          <el-cascader
            v-if="identityAndUsers.length"
            ref="myCascader"
            :props="{ children: 'users' }"
            :options="identityAndUsers"
            clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
    </my-dialog>

    <my-dialog
      ref="importDialog"
      title="导入"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleImport"
      >
      <el-upload
        class="upload-control"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        accept=".xls,.xlsx"
        :data="uploadData"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传Excel文件，且不超过10MB</div>
      </el-upload>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import PlanStatExaminationForm from '@/views/PlanStatManage/PlanStatExaminationForm'
import PlanStatExaminationModel from '@/model/PlanStatExaminationModel'
import planStatExaminationService from '@/services/planStatExaminationService'
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import PageOperation from '@/mixins/PageOperation'
import config from '@/config'
import auth from '@/common/auth'
import utility from '@/common/utility'
import { mapMutations } from 'vuex'

export default {
  name: 'PlanStatExaminationIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    MyElTable,
    PlanStatExaminationForm
  },
  data () {
    return {
      total: 0,
      planStatExaminationList: [],
      planStatExaminationModel: new PlanStatExaminationModel(),
      identityAndUsers: [],
      engineeringTypes: [],
      formDialogTitle: '',
      searchModel: {
        tenderName: ''
      },
      uploadData: {},
      uploadAction: `${config.reportHost}/UploadFile`,
      examinationModel: null
    }
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    showAddBtn () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) > -1
    },
    finalSubmitShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.finalSubmitIdentities.indexOf(identityGuid) !== -1
    }
  },
  methods: {
    shouldImport (examinationModel) {
      let notTransferTypes = ['G03', 'G15', 'G20']
      return notTransferTypes.indexOf(examinationModel.examinationType) !== -1
    },
    couldTransfer (examinationModel) {
      let notTransferTypes = ['G03', 'G15', 'G20']
      return this.userGuid === examinationModel.approvalUserGuid && notTransferTypes.indexOf(examinationModel.examinationType) === -1
    },
    handleImportClick (examinationModel) {
      this.examinationModel = examinationModel
      this.$refs.importDialog.open()
    },
    handleImport () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8)}&isanalysi=1&examination=${this.examinationModel.planStatExaminationGuid}&userguid=${auth.getUserInfo().userGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8),
        isanalysi: 1,
        examination: this.examinationModel.planStatExaminationGuid,
        userguid: auth.getUserInfo().userGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$message({
        type: 'success',
        message: '导入成功，您可到计量报表中查看！'
      })
      this.$refs.importDialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '导入失败！'
      })
      this.$refs.importDialog.close()
    },
    handleAddClick () {
      this.formDialogTitle = '添加计划统计'
      this.planStatExaminationModel = new PlanStatExaminationModel()
      this.$refs.formDialog.open()
    },
    handleSearchClick () {
      this._getPlanStatExaminationList()
    },
    handleSubmitClick (row) {
      this.planStatExaminationModel = new PlanStatExaminationModel(row)

      this._getNextUsers(this.planStatExaminationModel.resourceId)
    },
    doSubmit () {
      let nodes = this.$refs.myCascader.getCheckedNodes()
      if (!nodes.length) {
        this.$message({
          type: 'warning',
          message: '请选择传送对象！'
        })
        return
      }
      let userModel = nodes[0].data
      let flowModel = nodes[0].parent.data

      planStatExaminationService.transfer(userModel.userGuid, flowModel.stepName, flowModel.flowGuid, this.planStatExaminationModel.planStatExaminationGuid, flowModel.sortId)
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '传送成功！'
            })
            this._getPlanStatExaminationList()
            this.$refs.submitFormDialog.close()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '传送失败！'
          })
        })
    },
    handleFinalSubmitClick (row) {
      this.$confirm('确定要最终批复吗？')
        .then(() => {
          this.planStatExaminationModel = new PlanStatExaminationModel(row)
          planStatExaminationService.finalSubmit(this.planStatExaminationModel.resourceId)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '最终批复成功！'
                })
                this._getPlanStatExaminationList()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '最终批复失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleChartClick (row) {
      this.planStatExaminationModel = new PlanStatExaminationModel(row)
      this.$refs.chartDialog.open()
    },
    handleDetailClick (row) {
      this.setPlanStatExaminationGuid(row.planStatExaminationGuid)
      this.setTenderGuid(row.tenderGuid)
      this.setTenderName(row.tenderName)
      this.setYear(row.year)
      this.setMonth(row.month)
      this.setDate(row.date)
      this.setExaminationCode(row.examinationCode)
      this.setApprovalUserGuid(row.approvalUserGuid)

      this.$router.push(`/${row.examinationType}Index`)
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _planStatExaminationModel = new PlanStatExaminationModel(row)
          planStatExaminationService.delete(_planStatExaminationModel.resourceId)
            .then(res => {
              console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getPlanStatExaminationList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.planStatExaminationForm.validate()(valid => {
        if (valid) {
          planStatExaminationService.add(this.planStatExaminationModel)
            .then(res => {
              console.log(res)
              if (res.data.code === 1) {
                this.$refs.formDialog.close()
                this._getPlanStatExaminationList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '数据加载失败！'
              })
            })
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getPlanStatExaminationList () {
      planStatExaminationService.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'Periods.PeriodName desc,Tenders.TenderName asc,Tenders.SortId asc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.planStatExaminationList = res.data.data
            this.total = res.data.pageCount
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _edit () {
      planStatExaminationService.edit(this.planStatExaminationModel)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getPlanStatExaminationList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err.request.response)
        })
    },
    _getEngineeringTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderEngineeringType' })
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getNextUsers (planStatExaminationGuid) {
      planStatExaminationService.getUsers(planStatExaminationGuid)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            let data = res.data.data
            if (data.length) {
              data.forEach(flow => {
                flow.label = flow.stepName
                flow.value = flow.flowGuid
                if (flow.users && flow.users.length) {
                  flow.users.forEach(user => {
                    user.label = user.staffName
                    user.value = user.userGuid
                  })
                }
              })
              this.$refs.submitFormDialog.open()
              this.identityAndUsers = data
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    ...mapMutations('planStatExamination', {
      setPlanStatExaminationGuid: 'setPlanStatExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setTenderName: 'setTenderName',
      setYear: 'setYear',
      setMonth: 'setMonth',
      setDate: 'setDate',
      setExaminationCode: 'setExaminationCode',
      setApprovalUserGuid: 'setApprovalUserGuid'
    })
  },
  created () {
    this._getPlanStatExaminationList()
    this.refreshMethod = this._getPlanStatExaminationList
    this._getEngineeringTypes()
  }
}
</script>

<style scoped lang="scss">
.el-cascader {
  width: 100%;
}
::v-deep .el-upload {
  text-align: left;
}
</style>
