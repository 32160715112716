<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="planStatExaminationModel"
    label-width="100px"
    >
    <el-form-item label="类型：" prop="examinationType">
      <el-select
        v-model="planStatExaminationModel.examinationType"
        clearable>
        <el-option
          v-for="report in reports"
          :key="report.reportGuid"
          :label="`${report.reportAlias} ${report.reportName}`"
          :value="report.reportAlias"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="年份：" prop="year">
      <el-input-number
        v-model="planStatExaminationModel.year"
        :step="1"
        :min="2020"
        :max="2030"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item v-if="planStatExaminationModel.examinationType !== 'g07'" label="月份：" prop="month">
      <el-input-number
        v-model="planStatExaminationModel.month"
        :step="1"
        :min="1"
        :max="12"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item v-if="planStatExaminationModel.examinationType !== 'g07'" label="日期：" prop="date">
      <el-input-number
        v-model="planStatExaminationModel.date"
        :step="1"
        :min="1"
        :max="31"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import PlanStatExaminationModel from '@/model/PlanStatExaminationModel'
import PageInfo from '@/support/PageInfo'
import reportService from '@/services/reportService'
import config from '@/config'

export default {
  name: 'PlanStatExaminationForm',
  components: {
  },
  props: {
    planStatExaminationModel: {
      type: PlanStatExaminationModel,
      default () {
        return new PlanStatExaminationModel()
      }
    }
  },
  data () {
    return {
      reports: [],
      rules: {
        examinationType: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }
        ],
        year: [
          {
            required: true,
            message: '请输入年份',
            trigger: 'blur'
          }
        ],
        month: [
          {
            required: true,
            message: '请输入月份',
            trigger: 'blur'
          }
        ]
      },
      projects: [],
      tenders: [],
      identities: [],
      companys: [],
      roles: [],
      roleProps: {
        label: 'roleName',
        children: 'children'
      },
      tenderProps: {
        label: 'tenderName',
        children: 'children'
      }
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getReports (reportTemplateGuid) {
      reportService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'reports.sortId asc' }), { reportTemplateGuid: reportTemplateGuid, reportType: '计划统计' })
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.reports = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    }
  },
  created () {
    this._getReports()
  }
}
</script>
