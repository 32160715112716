import RestService from '@/services/RestService'
import utility from '@/common/utility'
import axios from 'axios'
import auth from '@/common/auth'

let resourceName = 'planStatExamination'
let getNextUsersUrl = 'getNextUsers'
let transferUrl = 'transfer'
let finalSubmitUrl = 'finalSubmit'

class PlanStatExaminationService extends RestService {
  getUsers (planStatExaminationGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, getNextUsersUrl), {
      params: {
        planStatExaminationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  transfer (userGuid, stepName, flowGuid, examinationGuid, flowSortId) {
    return axios.post(utility.getRestFullUrl(this.resourceName, transferUrl), utility.getFormData({
      userGuid,
      stepName,
      flowGuid,
      examinationGuid,
      flowSortId
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  finalSubmit (examinationGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, finalSubmitUrl), utility.getFormData({
      examinationGuid
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const planStatExaminationService = new PlanStatExaminationService(resourceName)

export default planStatExaminationService
